const Separator = ({ color = "black" }) => (
  <div
    style={{
      margin: "-10pt 0 -20px 0",
      filter: "drop-shadow(0 0 4px rgba(0, 0, 0, 1))",
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="100%"
      height="50pt"
      viewBox="0 0 1280.000000 640.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>
        Created by potrace 1.15, written by Peter Selinger 2001-2017
      </metadata>
      <g
        transform="translate(0.000000,640.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path d="M6335 3840 c-132 -3 -229 -9 -228 -14 1 -5 28 -19 60 -32 85 -31 135 -58 161 -87 l24 -25 -24 -16 c-26 -17 -135 -35 -210 -36 -67 0 -68 -16 0 -24 77 -9 151 -45 194 -95 35 -41 67 -121 69 -170 0 -14 4 -35 9 -46 10 -25 16 -11 24 58 16 134 109 236 244 267 28 7 52 16 52 21 0 5 -49 9 -109 9 -59 0 -122 5 -140 11 l-32 11 20 22 c19 20 115 70 224 115 27 12 46 25 42 31 -3 6 -37 9 -78 8 -40 -1 -175 -5 -302 -8z" />
        <path d="M6851 3428 c-70 -12 -116 -50 -156 -129 -37 -73 -68 -209 -47 -209 4 0 22 22 40 49 32 48 122 138 214 216 26 22 48 46 50 54 4 19 -46 29 -101 19z" />
        <path d="M5835 3410 c-5 -9 8 -28 41 -58 123 -112 204 -195 231 -236 49 -78 56 -52 22 75 -27 100 -65 160 -123 194 -53 31 -158 46 -171 25z" />
        <path d="M11795 3294 c-272 -83 -1112 -201 -1925 -269 -118 -10 -160 -17 -160 -26 0 -5 126 -9 298 -9 1125 0 2278 -128 2597 -287 57 -29 115 -80 115 -102 0 -10 -8 -7 -27 11 -99 92 -398 169 -858 222 -575 66 -1278 91 -2340 83 -1179 -9 -2060 -54 -2467 -128 -124 -22 -275 -26 -340 -8 -65 17 -133 55 -174 96 -45 47 -92 142 -100 206 -8 58 -20 60 -29 5 -22 -129 -92 -227 -203 -280 -61 -29 -78 -32 -182 -36 -88 -3 -141 1 -225 17 -397 74 -1272 119 -2475 128 -947 7 -1576 -11 -2100 -58 -568 -51 -951 -133 -1072 -230 -41 -32 -53 -26 -15 8 179 165 1085 297 2282 333 176 5 404 10 508 10 196 0 248 13 99 24 -163 13 -776 76 -982 101 -461 57 -866 127 -1032 179 l-78 24 23 -30 c78 -102 88 -127 66 -167 -25 -47 -90 -68 -289 -96 -416 -58 -625 -131 -687 -241 -28 -49 -29 -95 -2 -150 32 -67 34 -68 151 -45 360 72 1051 141 1848 186 1589 89 2931 62 3775 -76 284 -47 392 -34 489 59 44 43 92 136 102 199 8 52 19 48 28 -8 8 -53 47 -134 86 -181 75 -91 223 -117 445 -79 372 63 721 96 1303 122 305 13 1681 7 2017 -10 1158 -57 1891 -123 2358 -212 60 -11 110 -19 112 -17 18 17 65 126 65 149 0 99 -97 177 -292 237 -95 30 -193 49 -419 82 -199 29 -241 41 -279 83 -31 32 -20 78 35 149 25 32 42 58 37 57 -4 0 -43 -11 -87 -25z" />
        <path d="M6918 3196 c-68 -18 -112 -57 -147 -129 -32 -64 -67 -195 -56 -206 4 -3 15 5 26 19 75 97 115 140 197 211 50 44 92 86 92 94 0 27 -39 31 -112 11z" />
        <path d="M5763 3173 c-4 -11 5 -26 28 -46 123 -105 195 -177 231 -228 23 -32 45 -56 50 -51 9 9 -16 120 -43 188 -37 92 -125 154 -221 154 -27 0 -41 -5 -45 -17z" />
      </g>
    </svg>
  </div>
);
export default Separator;
